html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
margin:0;
padding:0;
border:0;
outline:0;
font-size:100%;
vertical-align:baseline;
background:transparent;
box-sizing: border-box;
font-family: Arial, Helvetica, sans-serif;
}

body {
line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
display:block;
}

nav ul {
list-style:none;
}

blockquote, q {
quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
content:'';
content:none;
}

a {
margin:0;
padding:0;
font-size:100%;
vertical-align:baseline;
background:transparent;
}

/* change colours to suit your needs */
ins {
background-color:#ff9;
color:#000;
text-decoration:none;
}

/* change colours to suit your needs */
mark {
background-color:#ff9;
color:#000; 
font-style:italic;
font-weight:bold;
}

del {
text-decoration: line-through;
}

abbr[title], dfn[title] {
border-bottom:1px dotted;
cursor:help;
}

table {
border-collapse:collapse;
border-spacing:0;
}

/* change border colour to suit your needs */
hr {
display:block;
height:1px;
border:0; 
border-top:1px solid #cccccc;
margin:1em 0;
padding:0;
}

input, select {
vertical-align:middle;
}

ol, ul{
    list-style: none;
}
textarea, button {
  font-family: Arial, Helvetica, sans-serif;
}
/*--------------- ここまではリセットcssです ----------------------*/

html{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-height: 100vh;
}
#root, body{
  min-height: 100vh;
  position: relative;
}



/*----------------------- ログイン画面 --------------------------*/
.wqc_login_outer{
    background:radial-gradient(rgba(204, 226, 209, 0.829),80%, rgb(122, 230, 172));
    background:-ms-radial-gradient(rgba(204, 226, 209, 0.829), rgb(122, 230, 172));
  }
  .wqc_login_title{
    margin-bottom:40px;
    font-size: 4rem;
    font-weight: bolder;
    border:15px solid #c33;  
    border-top: 1px solid red;
    border-radius:50%; 
    /* border-top:5px dashed #556DAE;   */
    color: rgb(54, 65, 56);
    padding:0px 30px;
    text-shadow:1px 1px 0 #FFF, -2px -2px 0 rgb(10, 200, 10),
                -1px 1px 0 #FFF, 1px -1px 0 #FFF,
                0px 1px 0 #FFF,  0-1px 0 #FFF,
                -1px 0 0 #FFF, 1px 0 0 #FFF;
    font-family: Georgia, 'Times New Roman', Times, serif;
    box-shadow: 1px 3px 3px 0px  #908b8b;
  }
  
  .wqc_login_inner{
    border: 3px solid rgb(255, 255, 255);
    background-color: #ffffff;
    padding: 50px;
    border-radius: 10px;
    width: 450px;
    box-shadow: 1px 3px 3px 0px  #cccccc;
  }
    
  .wqc_login_btn{
    border-radius: 10px;
    border: none;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: #595;
    color: #fff;
    border:2px solid #595;
    font-weight:bold; 
    margin: 10px 0px 25px 0px;
    transition: 0.3s;
  }
    
  .wqc_login_btn:hover{
    background-color: #ffffff;
    color:  #595;
  }

  .wqc_login_check{
    align-self: flex-end; 
    font-size: 0.9rem;
    margin: 0px 8px 40px 8px;
  }

  .wqc_login_sentens{ 
    font-size: 0.9rem;
    margin-bottom:50px;
  }

/*---------------------------------------------------------*/
/*----------------------ヘッダー-----------------------------*/
.wqc_header{
  /* background-color: #4CAF93; */
  background: linear-gradient( #4CAF93, 50%, #01906D);
  background: -ms-linear-gradient(bottom,  #01906D,  #4CAF93);
  white-space: nowrap;
}

.wqc_header_messe{
  color: #e00;
  
  background-color: #fff;
  font-size: 0.8rem;
  position: relative;
  padding:6px 12px;
  margin-left:8px;
  border-radius:5px;
  cursor: pointer;

}

/* .wqc_header_messe:hover{
  color:#00a;
  text-decoration: underline;
}

.wqc_header_messeparts{
  top: 100%;
  position: absolute;
  border-top:8px solid #fff ;
  border-left:8px solid transparent;
  border-right:8px solid transparent ;
} */

.wqc_header_right{
  margin:0px 24px;
}
.wqc_header_host{
  color:#fff;
  font-weight: 500;
}
.wqc_header_hospital_label{
  color:#333;
  /* font-weight: bolder; */
  font-size: 0.9rem;
  margin:0px 0px 0px 32px;
  background-color: #ddf;
  height: 30px;
  padding: 0px 16px;
  display: inline-flex;
  align-items: center;
  border-radius:5px 0px 0px 5px;
}
.wqc_header_hospital_wrap{
  height: 30px;
  display: inline-flex;
  align-items: center;
  padding: 0px 8px 0px 16px;
  margin-right:8px;
  background-color: #FFF;
  border:px solid #acc;
  border-radius:0px 5px 5px 0px;
}
.wqc_header_hospital{
  color: #00a;
  font-size: 0.9rem;
}

.wqc_header_hospital_icon{
  background-color:white;
  border-radius: 100%;
  padding:2px;
  margin-right: 8px;
}
.wqc_header_hospital_icon:hover{
  opacity: 0.7;
}

.wqc_header_user_wrap{
  height: 30px;
  display: inline-flex;
  align-items: center;
  padding:0px 24px;
  margin:0 8px;
  background-color: #FFF;
  border:1px solid #aaa;
  border-radius: 5px;
}

.wqc_header_logoutbtn{
  height: 30px;
  border-radius:15px;
  width: 100px;
  background-color:white ;
  border: 2px solid #666;
  box-shadow: 1px 1px 1px #ccc;
  cursor: pointer;
}
.wqc_header_logoutbtn:hover{
  background-color: #000;
  border: 2px solid #fff;
  color:#fff;
}


.wqc_header_menuarea{
  white-space: nowrap;
  width: 100%;
  padding-left:30px;
  /* background-color:#aaa ; */
  background: linear-gradient( #eee, 50%, #ccc);
  background: -ms-linear-gradient(bottom,  #ccc,  #eee);
  /* white-space: normal; */
}

.wqc_header_parts{
  display: block;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #000;
  border-left: 15px solid transparent;
  width: 0;
  margin-left:40px; 
}
.wqc_header_submenuwrap{
  border-radius:10px;
  border: #000 solid 1px;
  box-shadow: 0px 2px 2px 0px #cccccc;
  background: #FFF;
}
.wqc_header_submenu{
  white-space: nowrap;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: #c4dbd177 1px solid;
  padding: 10px 25px;
  text-align: left;
  min-width: 100%;
  cursor: pointer;
  font-size:13px;

}
.wqc_header_submenu:hover{
  background-color: #3faf7f;
  color: #fff;
  border-radius: inherit;
}

/*---------------------------------------------------------*/

/*________________________ トップページ ________________________*/

.wqc_top_body{
  padding-top:97px;
  padding-bottom:30px; 
  min-height: 100vh;
  background-color: #f0f0f0;
  width: 100%;
  min-width: 1000px;
}

.wqc_top_carditem{
  text-align: left;
  background-color: transparent;
  border: none;
  margin-bottom:8px;
  cursor: pointer; 
  color: #44c;
}

.wqc_top_carditem:hover{
  color: rgb(220, 100, 100);
  text-decoration:underline solid rgb(220, 100, 100);
}

/* __________________機能名【M004】【取り込み設定マスタメンテナンス（ユーザー別）】_____________________ */




.M004_search_area{
  background-color: #eee;
  padding:16px;
  margin-bottom: 32px;
  border-radius: 5px;
}

.M004_back_pagebtn{
  height: 35px;
  width: 150px;
  background-color: #E0E0E0;
  /* color:#333; */
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 20px;
  box-shadow: 2px 2px 2px #aaa;
  cursor: pointer;
  border: 2px solid #E0E0E0;
}

.M004_back_pagebtn:hover{
  background-color: #fff;
  border: 2px solid #000;
  color:#000;
}

.M004_submit_pagebtn{
  height: 35px;
  width: 150px;
  background-color: #8BC34A;
  /* color:#333; */
  font-weight: bold;
  font-size: 1rem;
  border-radius: 20px;
  box-shadow: 2px 2px 2px #aaa;
  cursor: pointer;
  border: 2px solid #8BC34A;
}

.M004_submit_pagebtn:hover{
  background-color: #fff;
  border: 2px solid #000;
  color:#000;
}

.M004_check_pagebtn{
  height: 35px;
  width: 150px;
  background-color: #2296F3;
  color:#fff;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 20px;
  box-shadow: 2px 2px 2px #aaa;
  cursor: pointer;
  border: 2px solid #2296F3;
}

.M004_check_pagebtn:hover{
  background-color: #fff;
  border: 2px solid #000;
  color:#000;
}

.M004_search_btn{
  height: 25px;
  width: 80px;
  background-color: #2296F3;
  color:#fff;
  font-weight: bold;
  /* font-size: 0.9rem; */
  border-radius: 5px;
  box-shadow: 2px 2px 2px #aaa;
  cursor: pointer;
  border: 2px solid #2296F3;
}

.M004_search_btn:hover{
  background-color: #fff;
  border: 2px solid #000;
  color:#000;
}
